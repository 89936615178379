


























































































































import { CommonActions, CommonGetters } from '@/store/modules/common/enums';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class CookieConsent extends Vue {
  @Prop()
  public showSettings!: boolean;

  protected static readonly COOKIE_NAME = 'cookie-consent';
  protected static readonly COOKIE_EXPIRATION = 182;
  protected static readonly GOOGLE_ANALYTICS_INFO_URL =
    'https://support.google.com/analytics/answer/4597324?hl=';
  protected static readonly APP_INSIGHTS_INFO_URL_1 =
    'https://docs.microsoft.com/';
  protected static readonly APP_INSIGHTS_INFO_URL_2 =
    '/azure/azure-monitor/app/data-retention-privacy';
  protected consentGiven = false;
  protected analyticsConsent = false;
  protected mainAlertVisible = false;
  protected readonly necessaryConsent = true;

  @Watch('showSettings')
  protected showSettingsListener(): void {
    if (this.showSettings) this.showSettingsModal();
  }

  protected get language(): string {
    return this.$store.getters[CommonGetters.Language];
  }

  protected mounted(): void {
    this.getCookie();
  }

  protected googleAnalyticsInfoUrl(): string {
    return `${CookieConsent.GOOGLE_ANALYTICS_INFO_URL}${this.language}`;
  }

  protected applicationInsightsInfoUrl(): string {
    return `${CookieConsent.APP_INSIGHTS_INFO_URL_1}${this.language}${CookieConsent.APP_INSIGHTS_INFO_URL_2}`;
  }

  protected showMainAlert(): void {
    this.mainAlertVisible = true;
  }

  protected showSettingsModal(): void {
    (this.$refs['settings-modal'] as any).show();
  }

  protected hideMainAlert(): void {
    this.mainAlertVisible = false;
  }

  protected hideSettingsModal(): void {
    (this.$refs['settings-modal'] as any).hide();
    this.$emit('settings-closed');
  }

  protected closeSettingsHandler(): void {
    if (!this.consentGiven) this.showMainAlert();
    this.$emit('settings-closed');
  }

  protected getCookie(): void {
    let consentCookie = this.getCookieByName(CookieConsent.COOKIE_NAME);
    if (!consentCookie) {
      this.consentNotYetGiven();
    } else {
      try {
        this.analyticsConsent = consentCookie.split('=')[1] === 'true';
        this.analyticsConsent
          ? this.enableAnalytics()
          : this.disableAnalytics();
        this.consentGiven = true;
      } catch (err) {
        this.deleteCookie();
        this.consentNotYetGiven();
      }
    }
    return;
  }

  protected consentNotYetGiven(): void {
    this.consentGiven = false;
    this.analyticsConsent = false;
    this.showMainAlert();
    this.disableAnalytics();
  }

  protected acceptAll(): void {
    this.analyticsConsent = true;
    this.setCookie();
    this.hideMainAlert();
    this.hideSettingsModal();
  }

  protected rejectAll(): void {
    this.analyticsConsent = false;
    this.setCookie();
    this.hideMainAlert();
    this.hideSettingsModal();
  }

  protected saveSettings(): void {
    this.setCookie();
    this.hideMainAlert();
    this.hideSettingsModal();
  }

  protected setCookie(): void {
    let expirationDate = new Date();
    expirationDate.setTime(
      expirationDate.getTime() +
        1000 * (CookieConsent.COOKIE_EXPIRATION * 24 * 60 * 60),
    );
    let cookieStr = `${CookieConsent.COOKIE_NAME}=analytics=${
      this.analyticsConsent
    }; expires=${expirationDate.toUTCString()}; path=/`;
    document.cookie = cookieStr;
    this.consentGiven = true;
    this.analyticsConsent ? this.enableAnalytics() : this.disableAnalytics();
  }

  protected deleteCookie(): void {
    const date = new Date();
    date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);
    document.cookie =
      CookieConsent.COOKIE_NAME +
      '=; expires=' +
      date.toUTCString() +
      '; path=/';
  }

  protected enableAnalytics(): void {
    this.$ga.enable();
    this.$store.dispatch(CommonActions.SetAnalyticsConsent, true);
    return;
  }

  protected disableAnalytics(): void {
    this.$ga.disable();
    this.$store.dispatch(CommonActions.SetAnalyticsConsent, false);
    return;
  }

  private getCookieByName(name: string): string | undefined {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');
    if (parts.length == 2) {
      return parts.pop()?.split(';').shift();
    }
  }
}
