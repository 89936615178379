























import { Component, Vue } from 'vue-property-decorator';
import CookieConsent from '@/components/cookie-consent/CookieConsent.vue';

@Component({
  components: {
    'ck-cookie-consent': CookieConsent,
  },
})
export default class Footer extends Vue {
  protected showCookieSettings = false;

  protected showCookieSettingsModal(): void {
    this.showCookieSettings = true;
  }

  protected updateSettingsStatus(): void {
    this.showCookieSettings = false;
  }

  private year = new Date().getFullYear();
}
